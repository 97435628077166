import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {isPlatformServer} from "@angular/common";


@Component({
  selector: 'app-title-display',
  templateUrl: './title-display.component.html',
  styleUrl: './title-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleDisplayComponent implements  OnChanges, AfterViewInit {
  @Input({transform: (value: string) => value.trim() }) title: string = '';
  @Input() productCount: number = 0;
  displayTitle: string = '';
  @ViewChild('titleElement') titleElement : ElementRef | undefined;
  @ViewChild('countElement', {static: false}) countElement : ElementRef | undefined;

  constructor(
    public changeDetectionRef: ChangeDetectorRef,
    private renderer :Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  ngAfterViewInit() {
    this.setCharacters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['title']) {
      if(changes['title'].currentValue !== changes['title'].previousValue) {
        this.displayTitle = '';
       this.setCharacters();
      }
    }
  }


  setCharacters(){
    if(isPlatformServer(this.platformId)) {
      return;
    }
    if(this.titleElement){
      window.innerWidth < 767 ?  this.setTextOnMobile() : this.setTextOnDesktop();
    }

  }

 updateDom(){
   const countString = `<div #countElement class="count_wrapper count_mobile">
      <span  class="inner_count"><i class="icon"><img src="assets/images/count_icon.svg" alt="" /></i>${this.productCount}</span>
    </div>`;
   this.renderer.setStyle(this.titleElement?.nativeElement,'visibility','visible')
   this.renderer.setProperty(this.titleElement?.nativeElement,'innerHTML', this.displayTitle + countString)
 }


  //This method is only responsible for handling mobile screens
  setTextOnMobile(){
    setTimeout(() => {
      const {lineCountWhenCountInvisible, lineCountWhenCountVisible} = this.getLineCount();
      if(lineCountWhenCountVisible === 1 && lineCountWhenCountInvisible === 1) {
        this.displayTitle = this.displayTitle ? this.displayTitle : this.title;
        this.updateDom();
      }
      else if(lineCountWhenCountVisible === 2 && lineCountWhenCountInvisible === 1){
        const titleString = (this.displayTitle ? this.displayTitle : this.title).replaceAll('<br/>','');
        let lastIndex = titleString.lastIndexOf(" ");
        this.displayTitle = `${titleString.slice(0, lastIndex) + '<br/>' + titleString.slice(lastIndex)}`;
        this.updateDom();
      } else if (lineCountWhenCountVisible === 2 && lineCountWhenCountInvisible === 2){
        this.displayTitle = (this.displayTitle ? this.displayTitle : this.title).replaceAll('<br/>', '');
        this.updateDom();
      } else if(lineCountWhenCountVisible > 2 || lineCountWhenCountInvisible !==  lineCountWhenCountVisible){
          const titleString = (this.displayTitle ? this.displayTitle : this.title).replaceAll('<br/>','');
          if(lineCountWhenCountVisible > 2 ){
            let lastIndex = titleString.lastIndexOf(" ");
            this.displayTitle = `${titleString.substring(0, lastIndex)}...`;
            this.setTextOnMobile();
          } else {
            this.updateDom()
          }
      }
    })
   this.changeDetectionRef.markForCheck();
  }




  //This method is only responsible for handling desktop screens
  setTextOnDesktop(){
    setTimeout(() => {
      const {lineCountWhenCountInvisible, lineCountWhenCountVisible} = this.getLineCount();
      if(lineCountWhenCountVisible === 1 && lineCountWhenCountInvisible === 1) {
        this.displayTitle =  this.displayTitle ? this.displayTitle : this.title
        this.updateDom();
      }
        if(lineCountWhenCountVisible > 1 ){
          const titleString = this.displayTitle ? this.displayTitle : this.title
          let lastIndex = titleString.lastIndexOf(" ");
          this.displayTitle = `${titleString.substring(0, lastIndex)}...`;
          this.setTextOnDesktop();
        } else {
          this.updateDom()
        }
    })
    this.changeDetectionRef.markForCheck();
  }



  getLineCount(){
    const titleElement = this.titleElement?.nativeElement;
    const count = this.countElement?.nativeElement;
    this.renderer.setStyle(count,  'display', 'none');
    const titleStyle = this.getTitleStyle();
    const lineHeight = parseFloat(titleStyle.lineHeight);
    const containerHeight = parseFloat(titleElement.clientHeight);
    const lineCountWhenCountInvisible = Math.round(containerHeight / lineHeight);
    this.renderer.setStyle(count,  'display', 'inline-block');
    const titleStyle1 = this.getTitleStyle();
    const lineHeight1 = parseFloat(titleStyle1.lineHeight);
    const containerHeight1 = parseFloat(titleElement.clientHeight);
    const lineCountWhenCountVisible = Math.round(containerHeight1 / lineHeight1);
    return {lineCountWhenCountInvisible, lineCountWhenCountVisible};
  }


  getTitleStyle(){
    const titleElement = this.titleElement?.nativeElement
    return window.getComputedStyle(titleElement);
  }

}
