import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {
  compare2Arrays,
  ENCODER,
  findScreenNameAndSpaceName,
  getWebLink,
  productFinderFunction
} from "../../../utils/common-function";
import {FooterService} from "../../../services/footer/footer.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Product} from "../../../interfaces/product";
import {isPlatformBrowser} from "@angular/common";
import {SpaceDetails} from "../../../interfaces/spaceDetails";
import {HomepageService} from "../../../services/homepage/homepage.service";
import {AmplitudeService} from "../../../services/tracking/amplitude.service";

export interface brandList {
  brandId: number;
  brandName: string
}

export interface filterObject {
  brandList: string[],
}

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrl: './product-view.component.scss'
})

export class ProductViewComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  //Input Variables
  @Input() spaceDetails: SpaceDetails | undefined;

  //Filter Variables
  @ViewChild('quoteBrandsDropdown') quoteBrandsDropdown: NgbDropdown | undefined;

  brandList: string[] = [];
  selectedBrands: string[] = [];
  selectedBrandBadges: string[] = [];

  filterObj: filterObject = {
    brandList: [],
  };
  reassignValue: boolean = true;

  //Product list variables
  routeSub: Subscription | undefined;
  modalSubscription: Subscription | undefined;

  productList: Product[] = [];
  displayedProducts: Product[] | undefined = [];

  brandMap: {[p: string]: string} = {};

  productIndex: number = -1
  showProductModal: boolean = false;

  brandDropdownEnterEventListener: Function | null = null;
  isScrollingTop: boolean = false;

  externalLinkPopup: boolean = false;

  constructor(
    public footerService: FooterService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public homepageService: HomepageService,
    public amplitudeService: AmplitudeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public changeDetectorRef: ChangeDetectorRef,
    public renderer2: Renderer2,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.spaceDetails = changes['spaceDetails'].currentValue;
    this.updateProductList();
  }

  get isMobile() {
    return isPlatformBrowser(this.platformId) && window.innerWidth < 767
  }

  ngOnInit() {
    this.updateProductList();
    this.routeSub = this.activatedRoute.queryParams.subscribe({
      next: (queryParams: Params) => {
        this.checkAndReassignParams(queryParams);
      }
    })
    this.footerService.isScrollingTop.subscribe({
      next: res => {
        this.isScrollingTop = res;
      }
    });

    this.modalSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.modalSubscription = this.activatedRoute.queryParams.subscribe({
      next: (queryParams: Params) => {
        if(queryParams['productId']) {
          const productIndex = this.productList.findIndex(product => productFinderFunction(product, queryParams['productId']))
          if(productIndex !== -1) {
            const product = this.productList[productIndex]
            const displayIndex = this.displayedProducts?.findIndex(product => productFinderFunction(product, queryParams['productId']))!
            this.homepageService.shouldScroll = false;
            this.openProductModal(displayIndex);
            this.homepageService.updateMetaAndTitleTags(`${product.productName}`, product.image)
            this.changeDetectorRef.detectChanges();
          } else {
            const currentRoute = this.getCurrentSubspaceRoute()
            this.router.navigate([currentRoute]).then()
          }
        } else {
          this.showProductModal = false;
        }

      }
    })

    if(isPlatformBrowser(this.platformId)) {
      if(this.homepageService.productToClick) {
        const productElement = document.getElementById(this.homepageService.productToClick)
        if(productElement) {
          productElement.click()
        }
        this.homepageService.CLICK_PRODUCT_ID.next('')
      }
    }
  }

  /**
   * Open mobile nav
   */
  menuBtnClick() {
    this.footerService.isMobileNavOpen.next(false);
  }

  /**
   * Update product filter list
   */
  updateProductList() {
    this.productList = this.spaceDetails?.products!;
    this.displayedProducts = this.productList!;
    // Filter Unique and brandId != 0 brands from product list
    this.brandMap = {};
    this.productList?.forEach(eachProduct => {
      if(eachProduct.brandName && !this.brandMap[eachProduct.brandName]) {
        this.brandMap[eachProduct.brandName.toLowerCase()] = eachProduct.brandName;
      }
    });
    this.brandList = Object.values(this.brandMap).sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
  }

//================================ Filter Section ====================================================================
  /**
   * Reassign old filters if dropdown clicked by clicking outside
   * @param event Dropdown close/open event
   * @param dropdownReference Dropdown reference
   */
  onChangeDropdown(event: boolean, dropdownReference: NgbDropdown) {
    if(event) {
      // Add document listener
      this.brandDropdownEnterEventListener = this.renderer2.listen('document', 'keydown', (event: KeyboardEvent) => {

        // Event listener for Enter
        if(event.code === 'Enter') {
          this.enterClickedOnItemHandler()
        }
      })
    } else {
      // Remove document listener
      if(this.brandDropdownEnterEventListener) {
        this.brandDropdownEnterEventListener()
        this.brandDropdownEnterEventListener = null;
      }
      if (this.reassignValue) {
        // Re-apply old filter
        this.selectedBrands = this.filterObj.brandList
      }
    }
    this.reassignValue = true;
  }

  /**
   * Check if selectedBrands object and selectedBrandsCopy is equal
   */
  get isEqualBrandObject() {
    const current = this.selectedBrands;
    const prev = this.selectedBrandBadges
    return compare2Arrays(current, prev);
  }

  /**
   * Apply Filter to url using query params
   * @param updatedValue to check specific case
   * @param dropdownReference Dropdown to be closed
   */
  applyFilterToUrl(updatedValue: 'brandList', dropdownReference?: NgbDropdown) {
    this.reassignValue = false;
    if (updatedValue === 'brandList') {
      this.filterObj.brandList = this.selectedBrands

      // Selected brand names
      const selectedBrands = this.selectedBrands

      const {screenName, spaceName} = findScreenNameAndSpaceName(this.spaceDetails);
      this.amplitudeService.brandFilterClick('brand', selectedBrands, screenName, spaceName)
      dropdownReference?.close();
    }
    this.goToPageOne();
  }

  /**
   * Remove specific filter
   * @param index Index of brand to be removed
   */
  removeOnClose(index: number) {
    this.filterObj.brandList.splice(index, 1);
    this.goToPageOne();
  }

  /**
   * Clear all applied filters
   */
  clearAllFilters() {
    this.filterObj = {
      brandList: [],
    };
    this.goToPageOne();
  }

  /**
   * Routes to page 1 with query params
   */
  goToPageOne(){
    const filters = {
      brandList: this.filterObj.brandList,
    }
    const q = filters.brandList.length ? ENCODER.encryptData(filters) : undefined;
    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    const queryParams = {
      ...currentQueryParams,
      q,
    };
    this.router.navigate([`${this.router.url.split('?')[0]}`], {queryParams}).then();
  }

  /**
   * Get params received from url and update
   * @param param
   */
  checkAndReassignParams(param: Params) {
    if(param['q']) {
      try {
        const decoded = ENCODER.decryptData(param['q']);
        this.filterObj.brandList = decoded['brandList'];
        this.selectedBrands = this.filterObj.brandList;
        this.selectedBrandBadges = this.filterObj.brandList;
        if(this.filterObj.brandList.length) {
          const lowerCaseBrands = this.filterObj.brandList.map(each => each.toLowerCase());
          this.displayedProducts = [...this.productList].filter(eachProduct => lowerCaseBrands.includes(eachProduct.brandName?.toLowerCase()!));
        } else {
          this.displayedProducts = [...this.productList];
        }
      } catch (e) {
        this.filterObj.brandList = [];
        this.goToPageOne();
      }
    } else {
      this.selectedBrands = [];
      this.selectedBrandBadges = [];
      this.displayedProducts = [...this.productList];
    }
  }
//================================ End Filter Section ==================================================================

  /**
   * Route to productId url to open modal
   * @param event
   * @param productIndex
   */
  openProduct(event: any, productIndex: number) {
    const product = this.displayedProducts?.[productIndex]!
    if(!product.affiliateUrl && !product.productUrl) {
      event.preventDefault()
    }
    const {screenName, spaceName} = findScreenNameAndSpaceName(this.spaceDetails);
    const isMobile = isPlatformBrowser(this.platformId) && window.innerWidth < 767
    if(isMobile) {
      event.preventDefault();
      this.amplitudeService.productCardClickEvent(product, screenName, spaceName)
      const currentRoute = this.getCurrentSubspaceRoute()
      const currentQueryParams = this.activatedRoute.snapshot.queryParams;
      const queryParams = {
        ...currentQueryParams,
        productId: product.showroomProductName,
      }
      this.router.navigate([currentRoute], {queryParams}).then()
    } else {
      if(this.homepageService.IS_IFRAME && !isMobile) {
        // Open modal and prevent default
        event.preventDefault();
        this.externalLinkPopup = true;
      } else {
        this.amplitudeService.productCardClickEvent(product, screenName, spaceName)
      }
    }
  }

  /**
   * Open Product modal
   * @param productIndex Product index
   */
  openProductModal(productIndex: number) {
    this.productIndex = productIndex
    this.showProductModal = true;
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
    this.modalSubscription?.unsubscribe();
  }

  /**
   * Get name of current subspace
   */
  getCurrentSubspaceRoute() {
    const {screenName, spaceName} = findScreenNameAndSpaceName(this.spaceDetails!)
    if(screenName === 'space_page') {
      return `/${this.footerService.PropertyId}/${this.spaceDetails?.subspaceName!}`
    } else {
      const screenMap: any = {
        'local_items': 'local-items',
        'our_favorites': 'our-favorites',
        'all_products': 'all-products'
      }
      const route = screenMap[screenName]
      return `/${this.footerService.PropertyId}/${route}`
    }
  }

  /**
   * Event handler for when enter is clicked on an item in the brands filter or when enter is clicked in document after dropdown is open
   */
  enterClickedOnItemHandler() {
    if(!this.isEqualBrandObject) {
      this.reassignValue = true;
      this.applyFilterToUrl('brandList', this.quoteBrandsDropdown!)
      this.quoteBrandsDropdown?.close();
    } else {
      this.quoteBrandsDropdown?.close();
    }
  }

  /**
   * To blur brands button
   * @param event Click event
   */
  onBrandsDropdownButtonClick(event: any) {
    if(isPlatformBrowser(this.platformId)) {
      const activeElement  = document.activeElement
      if(activeElement) {
        (activeElement as HTMLElement).blur()
      }
    }
  }

  protected readonly getWebLink = getWebLink;
}
