<!-- co brand module html start here  -->
<section class="co_brand_module_sec">
  <div class="mx-1660 container">
    <div class="co_brand_inner_sec">
      <div class="left_sec">
        <figure class="brand_img_sec">
          <img
            src="{{coBrandingImage!}}"
            srcset="
                  {{coBrandingImage! | imageSize: '_hero_phone' }} 800w,
                  {{coBrandingImage! | imageSize: '_hero_phone' }} 1200w,
                  {{coBrandingImage! | imageSize: '_hero_desk' }} 1920w,
                  {{coBrandingImage! }} 2560w
                "
            sizes="
                  (max-width: 800px) 800px,
                  (max-width: 1200px) 1200px,
                  (max-width: 1920px) 1920px,
                  2560px
                "
            onError="this.src='assets/images/hero_banner.jpg'" alt="{{showroomDetails?.showroomName}}" />
        </figure>
      </div>
      <div class="right_sec">
        <div class="co_brand_desc">
          @if(showroomDetails?.propertyLogo) {
            <div class="co_brand_img">
              <a (click)="openLogoLink($event)" href="{{showroomDetails?.propertyLogoLink | applyFn: getWebLink}}" [ngClass]="{'deactive-logo-link' : showroomDetails?.propertyLogoLink == ''}" (click)="amplitudeService.propertyLogoClick(showroomDetails?.propertyLogoLink!)" target="_blank">
                <img [ngClass]="{'br-100': img.height === img.width}" [src]="showroomDetails?.propertyLogo" #img  alt="{{ showroomDetails?.showroomName }}" />
              </a>
            </div>
          }
          <label class="subtitle1">Curated by&nbsp;<a (click)="openLogoLink($event)" [ngClass]="{'deactive-logo-link1' : showroomDetails?.propertyLogoLink == ''}" href="{{showroomDetails?.propertyLogoLink}}" target="_blank">{{ curatedBy }},&nbsp;</a><span class="powered_by"> powered by Minoan</span></label>
          <h1 class="heading_5">
            Touch, feel, and try before you buy — with Minoan, shopping becomes an immersive experience that transforms the way you discover and select products.
          </h1>
        </div>
      </div>
    </div>
  </div>
</section>
