import { utils } from '@amplitude/rrweb';
import { BeaconTransport } from '../beacon-transport';
import { getGlobalScope } from '@amplitude/analytics-client-common';
var getWindowHeight = utils.getWindowHeight,
  getWindowWidth = utils.getWindowWidth;
/**
 * This is intended to watch and update max scroll activity when loaded for a particular page.
 * A new instance should be created if the page URL changes, since by default it does not reset
 * it's max scroll state. It is intended to send very few and very small events utilizing the
 * Beacon API.
 * @see {@link BeaconTransport} for more details on Beacon API usage.
 */
var ScrollWatcher = /** @class */function () {
  function ScrollWatcher(transport) {
    var _this = this;
    this.timestamp = Date.now();
    this.hook = function (e) {
      _this.update(e);
    };
    this.send = function (deviceIdFn) {
      return function (_) {
        var deviceId = deviceIdFn();
        var globalScope = getGlobalScope();
        if (globalScope && deviceId) {
          _this.transport.send(deviceId, {
            version: 1,
            events: [{
              maxScrollX: _this._maxScrollX,
              maxScrollY: _this._maxScrollY,
              maxScrollWidth: _this._maxScrollWidth,
              maxScrollHeight: _this._maxScrollHeight,
              viewportHeight: getWindowHeight(),
              viewportWidth: getWindowWidth(),
              pageUrl: globalScope.location.href,
              timestamp: _this.timestamp,
              type: 'scroll'
            }]
          });
        }
      };
    };
    this._maxScrollX = 0;
    this._maxScrollY = 0;
    this._maxScrollWidth = getWindowWidth();
    this._maxScrollHeight = getWindowHeight();
    this.transport = transport;
  }
  ScrollWatcher.default = function (context, config) {
    return new ScrollWatcher(new BeaconTransport(context, config));
  };
  Object.defineProperty(ScrollWatcher.prototype, "maxScrollX", {
    get: function () {
      return this._maxScrollX;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(ScrollWatcher.prototype, "maxScrollY", {
    get: function () {
      return this._maxScrollY;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(ScrollWatcher.prototype, "maxScrollWidth", {
    get: function () {
      return this._maxScrollWidth;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(ScrollWatcher.prototype, "maxScrollHeight", {
    get: function () {
      return this._maxScrollHeight;
    },
    enumerable: false,
    configurable: true
  });
  ScrollWatcher.prototype.update = function (e) {
    var now = Date.now();
    if (e.x > this._maxScrollX) {
      var width = getWindowWidth();
      this._maxScrollX = e.x;
      var maxScrollWidth = e.x + width;
      if (maxScrollWidth > this._maxScrollWidth) {
        this._maxScrollWidth = maxScrollWidth;
      }
      this.timestamp = now;
    }
    if (e.y > this._maxScrollY) {
      var height = getWindowHeight();
      this._maxScrollY = e.y;
      var maxScrollHeight = e.y + height;
      if (maxScrollHeight > this._maxScrollHeight) {
        this._maxScrollHeight = maxScrollHeight;
      }
      this.timestamp = now;
    }
  };
  return ScrollWatcher;
}();
export { ScrollWatcher };
