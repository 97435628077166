import {SpaceDetails} from "../interfaces/spaceDetails";
import {Product} from "../interfaces/product";
import {Base64} from 'js-base64'

/**
 * Compares whether 2 arrays are equal or not
 * @param arr1 Array 1
 * @param arr2 Array 2
 * @param [checkIndex=false] false = Check if 2 arrays have same values regardless of index, true = Check whether 2 arrays are exactly same i.e. all values are same indices
 */
const compare2Arrays = (arr1: Array<any>, arr2: Array<any>, checkIndex: boolean = false): boolean => {
  if(arr1.length != arr2.length){
    return false;
  } else {
    if(!checkIndex){
      return arr1.every((everyValueInArr1: any) => arr2.indexOf(everyValueInArr1) != -1)
    } else {
      return arr1.every((everyValueInArr1: any, index: number) => arr2[index] == everyValueInArr1)
    }
  }
}

/**
 * To find screen name and subspace name
 * @param spaceDetails Space details
 */
const findScreenNameAndSpaceName = (spaceDetails: SpaceDetails | undefined ) => {
  let screenName: string;
  let spaceName: string | undefined;
  switch (spaceDetails?.subspaceId) {
    case 'local_items':
      screenName = 'local_items'
      spaceName = undefined;
      break;
    case 'our_favorite':
      screenName = 'our_favorites'
      spaceName = undefined;
      break;
    case 'all_products':
      screenName = 'all_products'
      spaceName = undefined;
      break;
    default:
      screenName = 'space_page'
      spaceName = spaceDetails?.title!
  }

  return {screenName, spaceName}
}

/**
 * Product finder function
 * @param each Each product being iterated
 * @param productId Product Id from URL
 */
const productFinderFunction = (each: Product, productId: string) => {
  return !!each.showroomProductName && each.showroomProductName.toLowerCase() === productId.toLowerCase()
}

const ENCODER = {
  /**
   * Method to encrypt Data
   * @param data
   */
  encryptData: (data: any): string => {
    const stringData = JSON.stringify(data)
    // return JSON.stringify(data)
    try {
      return Base64.encode(stringData)
    } catch (e) {
      console.log(e);
      return '';
    }
  },

  /**
   * Method to decrypt Data
   * @param data
   */
  decryptData: (data: string): any => {
    // return JSON.parse(data)
    try {
      const stringData = Base64.decode(data)
      return JSON.parse(stringData)
    } catch (e) {
      return '';
    }
  }
}

/**
 * Add http in url
 * @param url
 */
const getWebLink = (url: string | null) : string | null => {
  if(url && !url.startsWith('http')) {
    return 'https://' + url;
  }
  return url;
}

export {
  compare2Arrays,
  findScreenNameAndSpaceName,
  productFinderFunction,
  ENCODER,
  getWebLink,
}
